import { NotificationsActiveOutlined } from '@mui/icons-material';
import { Badge, Menu } from '@mui/material';
import { useAppContext } from 'Context/AppContext';
import { ResponseDataType, getRequest } from 'api';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { NotiItem } from './NotiItem';

export interface NotificationInfo {
  id: string;
  root_id: string;
  organization: string;
  stream?: string;
  content: string;
  sub_content?: string;
  status: number;
  type: number;
  stream_type: number;
  create_at: string;
  creator: { name: string; avatar: string };
}

interface NotiResType {
  notiList: NotificationInfo[];
}

export const NotificationDropdown = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { currentOrg, onEvent } = useAppContext();
  const [numberOfNotSeen, setNumberOfNotSeen] = useState(0);
  const [notiList, setNotiList] = useState<NotificationInfo[]>([]);

  const handleClick = (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const getNotifications = async () => {
      const res: ResponseDataType<NotiResType> = await getRequest(
        `/notification/get-notification?orgId=${currentOrg}`
      );

      if (res.code) {
        toast.error(res.message);
      } else {
        setNumberOfNotSeen(res.data?.notiList.length || 0);
        setNotiList(res.data?.notiList || []);
      }
    };

    getNotifications();
  }, [currentOrg]);

  useEffect(() => {
    setNumberOfNotSeen(
      notiList.reduce((value, cur) => (value += 1 - cur.status), 0)
    );
  }, [notiList]);

  useEffect(() => {
    onEvent('new-notification', (newNoti) => {
      setNotiList((prev) => [newNoti, ...prev]);
    });
  }, [onEvent]);

  const seeNotification = (index: number) => {
    if (notiList[index].status) return;

    getRequest(`/notification/see-notification/${notiList[index].id}`);
    setNotiList((prev) => {
      prev[index].status = 1;
      return prev;
    });
    setNumberOfNotSeen((prev) => prev - 1);
  };

  return (
    <div>
      <Badge
        id='notificaiton-dropdown'
        badgeContent={numberOfNotSeen}
        color='error'
        sx={{ margin: '3px 5px 0 15px', cursor: 'pointer' }}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <NotificationsActiveOutlined />
      </Badge>
      <Menu
        id='account-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        autoFocus={false}
        MenuListProps={{
          'aria-labelledby': 'notificaiton-dropdown',
        }}
        PaperProps={{
          sx: {
            width: 320,
            right: 20,
            left: 'auto !important',
            borderRadius: '10px',
            padding: '5px',
            marginTop: '10px',
            boxShadow: 'none',
            border: 'solid 1px #cacaca',
            maxHeight: '400px',
          },
        }}
      >
        {notiList.map((noti, index) => (
          <NotiItem
            key={index}
            handleCloseMenu={handleCloseMenu}
            noti={noti}
            handleSeeNoti={() => seeNotification(index)}
          />
        ))}
        {!notiList.length && (
          <div style={{ fontSize: 18, fontWeight: 600, marginLeft: 10 }}>
            Bạn hiện chưa có thông báo nào
          </div>
        )}
      </Menu>
    </div>
  );
};
