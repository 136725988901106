export enum StreamFollowerType {
  PROPOSAL = 1,
  REQUEST = 2,
}

export enum StreamType {
  PROPOSAL = 1,
  TASK = 2,
}

export enum ProposalType {
  GENERAL = 3,
}

export enum RequestType {
  PAYMENT = 7,
  ADVANCE = 8,
  REIMBURSEMENT = 9,
}

export enum TaskType {
  APPROVE_PROPOSAL = 2,
  PROCESS_PAYMENT = 10,
  EXECUTE_PROPOSAL = 11,
  CONFIRM_REQUEST = 12,
}
