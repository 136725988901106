import { Row } from 'antd';
import { MemberInfoDataType } from './type';
import { Avatar } from 'Components';
import { ImgHTMLAttributes } from 'react';

enum DepartmentNamecode {
  CEO = 'CEO',
  CFO = 'CFO',
  KTT = 'KTT',
  KTNB = 'KTNB',
  KTNBTA = 'KTNBTA',
}

const departmentInfo = {
  [DepartmentNamecode.CEO]: 'CEO',
  [DepartmentNamecode.CFO]: 'CFO',
  [DepartmentNamecode.KTT]: 'Kế toán trưởng',
  [DepartmentNamecode.KTNB]: 'Kế toán nội bộ - The Nine',
  [DepartmentNamecode.KTNBTA]: 'Kế toán nộ bộ - Tràng An',
};

interface CreatorFullInfoPropsType extends MemberInfoDataType {
  avatarProps?: ImgHTMLAttributes<HTMLImageElement>;
}

export const MemberFullInfo = (props: CreatorFullInfoPropsType) => {
  const { name, avatar, departmentCode, departmentName, role, avatarProps } =
    props;

  return (
    <Row align='middle'>
      <Avatar
        src={avatar}
        style={{ height: 25, width: 25, borderRadius: '50%' }}
        {...avatarProps}
      />
      <div style={{ marginLeft: 10, fontWeight: 400 }}>
        <span style={{ fontWeight: 700 }}>{name}</span>
        <br />
        {departmentCode && Object.keys(departmentInfo).includes(departmentCode)
          ? departmentInfo[departmentCode as DepartmentNamecode]
          : `${role ? 'Trưởng phòng' : 'Nhân viên'} - Phòng ${departmentName}`}
      </div>
    </Row>
  );
};

export const MemberInfo = (member: MemberInfoDataType) => {
  const { name, departmentCode, departmentName, role } = member;

  return (
    <>
      {name}
      {' ('}
      {departmentCode && Object.keys(departmentInfo).includes(departmentCode)
        ? departmentInfo[departmentCode as DepartmentNamecode]
        : `${role ? 'Trưởng phòng' : 'Nhân viên'} - Phòng ${departmentName}`}
      {')'}
    </>
  );
};
