import { Link } from 'react-router-dom';
import { Apps } from '@mui/icons-material';

import styles from '../layout.module.css';
import { OrgLogo } from 'Components';
import { useAppContext } from 'Context/AppContext';
import { AccountDropdown, NotificationDropdown } from './Components';

export default function Header() {
  const { currentOrg, orgList } = useAppContext();

  return (
    <div className={styles.header}>
      <Link to='/' className={styles.organization}>
        <OrgLogo
          className={styles.organization_logo}
          src={orgList?.find((org) => org.id === currentOrg)?.logo}
        />
        {/* <p className={styles.organization_name}>Adot Workspace</p> */}
      </Link>

      <div className={styles.account}>
        <Apps sx={{ cursor: 'pointer', fontSize: '30px' }} />
        {currentOrg && <NotificationDropdown />}
        <AccountDropdown />
      </div>
    </div>
  );
}
